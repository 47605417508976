import React, { Component } from 'react';
import { FcCustomerSupport } from 'react-icons/fc';

class Messenger extends Component {
    render() {
        return (
            <div className='messenger'>
                <a href='https://wa.me/+2347033805477?text=Hello,I will like to inquire about your services'>
                <FcCustomerSupport></FcCustomerSupport>
                </a>
            </div>
        );
    }
}

export default Messenger;