import React, { Component } from "react";
import "../styles/footer.css";
import { MdCall, MdEmail, MdLocationPin } from "react-icons/md";
import { BsFacebook, BsInstagram, BsYoutube } from "react-icons/bs";

class Footer extends Component {
  render() {
    return (
      <div>
        <div className="footer-bg">
          <div className="footer-col">
            <p className="footer-title">About Us</p>
            <div className="footer-border"></div>
            <p className="footer-sub">
              At Artino Projects Limited, we are committed to conducting our
              business with the highest level of integrity and earning the trust
              of our clients. Our code of integrity contains our guilding
              ethical principles and sets forth the behavior we expect of all
              our employees, directors and officers, as well as our business
              partners.
            </p>
          </div>
          <div className="footer-col">
            <p className="footer-title">Contact Us</p>
            <div className="footer-border"></div>
            <p className="footer-sub">
              <MdLocationPin></MdLocationPin>&nbsp;&nbsp;53 East West
              Road,Portharcourt, Rivers State, Nigeria
            </p>
            <p className="footer-sub">
              <MdCall></MdCall>&nbsp;&nbsp;
              <a className="ft-contact" href="tel:+2349137312468">
                +234 913 731 2468
              </a>
              ,&nbsp;
              <a className="ft-contact" href="tel:+2347033805477">
                +234 703 380 5477
              </a>
            </p>
            <p className="footer-sub">
              <MdEmail></MdEmail>
              <a className="ft-contact" href="mailto:info@artinoprojects.com">
                &nbsp;&nbsp; info@artinoprojects.com
              </a>
            </p>
            <br></br>
            <p className="footer-sub">
              <a className="ft-contact" href="https://www.facebook.com/share/EXNdn3mdQ12xa8Wx/?mibextid=qi2Omg">
                <BsFacebook style={{fontSize:'30px'}}></BsFacebook> &nbsp;&nbsp;
              </a>

              <a className="ft-contact" href="https://www.instagram.com/artinogroup?utm_source=qr&igsh=ZmVmNXBwd3NwNDA4">
                <BsInstagram style={{fontSize:'30px'}}></BsInstagram>&nbsp;&nbsp;
              </a>

              <a className="ft-contact" href="https://www.youtube.com/@artino_projects">
                <BsYoutube style={{fontSize:'30px'}}></BsYoutube>
              </a>
            </p>
          </div>
          <div className="footer-col">
            <p className="footer-title">Consultation</p>
            <div className="footer-border"></div>
            <input className="footer-input" placeholder="Fullname"></input>
            <input className="footer-input" placeholder="Email"></input>
            <button className="footer-but">Submit</button>
          </div>
        </div>
        <div className="footer-copy">
          <p>Copyright &copy; 2024 Artino Projects Limited</p>
        </div>
      </div>
    );
  }
}

export default Footer;
