import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/home.css";

const ServicesComp = ({ allowAnimation = true }) => {
  if (allowAnimation) {
    return (
      <div className="hm-abt-contain">
        <div>
          <ScrollAnimation animateIn="fadeIn" animateOnce="true">
            <h2 style={{ textAlign: "center" }}>
              Our <span className="fg-shade-one-dark">Services</span>
            </h2>
          </ScrollAnimation>
        </div>
        <div className="hm-service-container">
          <ScrollAnimation animateIn="flipInX" animateOnce={true} delay={0}>
            <div className="hm-service-body">
              <div className="hm-service-icon">
                <svg
                  width="50px"
                  height="50px"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="hm-service-icon"
                    d="M291.17 16.975c-5.923.122-11.645 2.246-16.06 7.398-17.652 20.61-13.472 36.05-10.667 42.856 16.607-51.264 33.81-5.23 55.442-25.076 8.615-7.904-10.947-25.546-28.715-25.18zM208 67.107c-19.994.235-33.533 26.42-25.31 33.495 22.252 19.138 32.473-26.055 58.884-4.803-6.212-13.538-13.58-28.927-33.574-28.693zm93.832 8.967c-12.564.318-21.72 9.49-29.832 17.832 32-11.19 26.51 34.82 53.883 24.23 10.118-3.914 6.12-33.12-12.643-40.032-4.104-1.512-7.89-2.12-11.408-2.03zM247 106.107v32h-30.38l-6.4 32H199v18h7.62l-15.417 77.09-1.594.91H176v18h11.422l-34.8 174H128v18h256v-18h-24.62l-34.802-174H336v-18h-13.61l-1.593-.91-15.418-77.09H313v-18h-11.22l-6.4-32H265v-32h-18zm-15.62 50H247v14h-18.42l2.8-14zm33.62 0h15.62l2.8 14H265v-14zm-40.02 32H247v34.92l-26.03-14.875 4.01-20.045zm40.02 0h22.02l4.01 20.045L265 223.027v-34.92zm-47.752 38.65l20.61 11.778-25.928 14.82 5.318-26.597zm77.504 0l5.318 26.597-25.93-14.82 20.612-11.776zM247 254.046v12.062h-21.11L247 254.045zm18 0l21.11 12.062H265v-12.062zm-59.22 30.062H247v47.846l-47.615-15.87 6.394-31.976zm59.22 0h41.22l6.407 32.028L265 332.61v-48.503zm-69.172 49.762l33.38 11.126-38.253 13.234 4.873-24.36zm120.36.08l4.515 22.57-35.525-11.84 31.01-10.73zM265 356.925l48.54 16.18L265 399.58v-42.656zm-18 .963v41.692l-46.77-25.512L247 357.89zm-61.688 28.55l51.893 28.302-64.6 35.237 12.708-63.54zm141.375 0l12.708 63.54-64.598-35.238 51.89-28.303zM247 429.903v28.203h-51.705L247 429.904zm18 0l51.705 28.203H265v-28.203z"
                  />
                </svg>
              </div>
              <div>
                <h3 className="hm-service-title">Drilling Equipment</h3>
                <p>
                  We provide high-performance drilling equipment designed to
                  optimize your operations. Our reliable tools ensure efficiency
                  and safety in the toughest environments.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="flipInX" animateOnce={true} delay={500}>
            <div className="hm-service-body">
              <div className="hm-service-icon">
                <svg
                  width="50px"
                  height="50px"
                  viewBox="0 0 512 512"
                  version="1.1"
                  id="Layer_1"
                >
                  <g>
                    <g>
                      <g>
                        <rect x="480" y="480" width="16" height="16" />
                        <path
                          d="M496,176v-16H336v16h16v72h-16v16h16v72h-24.8L296.888,48H312V0h-96v48h15.112L200.8,336h-13.08L63.664,188.68
                    C61.976,172.608,48.512,160,32,160c-17.648,0-32,14.352-32,32c0,14.872,10.232,27.288,24,30.864V480H0v16h464v-16h-16v-80h48v-64
                    h-16v-72h16v-16h-16v-72H496z M368,176h96v72h-96V176z M368,264h96v72h-96V264z M310.624,331.312L243.312,264h60.224
                    L310.624,331.312z M301.096,240.816L249.96,184h45.152L301.096,240.816z M242.992,88l4.208-40h33.6l4.208,40H242.992z
                     M286.696,104l5.544,52.68L255.368,104H286.696z M280.632,168h-46.064l6.024-57.208L280.632,168z M232,16h64v16h-0.8h-62.4H232
                    V16z M232.424,188.424L286.04,248h-59.888L232.424,188.424z M224.104,267.416L292.688,336H216.88L224.104,267.416z M166.8,336
                    h-34.592l-11-16h32.12L166.8,336z M115.968,275.632L139.856,304h-29.648l-3.536-5.144L115.968,275.632z M104.288,261.752
                    l-8.504,21.256l-29.08-42.296l31.08,13.32L104.288,261.752z M59.344,208.384l15.512,18.424l-23.04-9.872
                    C54.808,214.544,57.36,211.68,59.344,208.384z M16,192c0-8.824,7.176-16,16-16s16,7.176,16,16c0,8.824-7.176,16-16,16
                    S16,200.824,16,192z M104,480H40V230.12l48.64,70.744l-0.064,0.168l0.248,0.096L112.792,336H56v64h48V480z M184,448h-8v16h8v16
                    h-64v-16h40v-16h-40v-48h64V448z M352,480H200v-80h152V480z M432,448h-8v16h8v16h-64v-16h40v-16h-40v-48h64V448z M480,384H72v-32
                    h127.12h129.76H480V384z"
                        />
                        <rect x="384" y="192" width="16" height="16" />
                        <rect x="416" y="192" width="16" height="16" />
                        <rect x="432" y="304" width="16" height="16" />
                        <rect x="400" y="304" width="16" height="16" />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div>
                <h3 className="hm-service-title">Mud Pump & Parts</h3>
                <p>
                  Our durable mud pumps and parts are engineered for maximum
                  reliability and longevity. They are essential for maintaining
                  optimal drilling fluid circulation.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="flipInX" animateOnce={true} delay={1000}>
            <div className="hm-service-body">
              <div className="hm-service-icon">
                <svg
                  width="50px"
                  height="50px"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="hm-service-icon"
                    d="M291.17 16.975c-5.923.122-11.645 2.246-16.06 7.398-17.652 20.61-13.472 36.05-10.667 42.856 16.607-51.264 33.81-5.23 55.442-25.076 8.615-7.904-10.947-25.546-28.715-25.18zM208 67.107c-19.994.235-33.533 26.42-25.31 33.495 22.252 19.138 32.473-26.055 58.884-4.803-6.212-13.538-13.58-28.927-33.574-28.693zm93.832 8.967c-12.564.318-21.72 9.49-29.832 17.832 32-11.19 26.51 34.82 53.883 24.23 10.118-3.914 6.12-33.12-12.643-40.032-4.104-1.512-7.89-2.12-11.408-2.03zM247 106.107v32h-30.38l-6.4 32H199v18h7.62l-15.417 77.09-1.594.91H176v18h11.422l-34.8 174H128v18h256v-18h-24.62l-34.802-174H336v-18h-13.61l-1.593-.91-15.418-77.09H313v-18h-11.22l-6.4-32H265v-32h-18zm-15.62 50H247v14h-18.42l2.8-14zm33.62 0h15.62l2.8 14H265v-14zm-40.02 32H247v34.92l-26.03-14.875 4.01-20.045zm40.02 0h22.02l4.01 20.045L265 223.027v-34.92zm-47.752 38.65l20.61 11.778-25.928 14.82 5.318-26.597zm77.504 0l5.318 26.597-25.93-14.82 20.612-11.776zM247 254.046v12.062h-21.11L247 254.045zm18 0l21.11 12.062H265v-12.062zm-59.22 30.062H247v47.846l-47.615-15.87 6.394-31.976zm59.22 0h41.22l6.407 32.028L265 332.61v-48.503zm-69.172 49.762l33.38 11.126-38.253 13.234 4.873-24.36zm120.36.08l4.515 22.57-35.525-11.84 31.01-10.73zM265 356.925l48.54 16.18L265 399.58v-42.656zm-18 .963v41.692l-46.77-25.512L247 357.89zm-61.688 28.55l51.893 28.302-64.6 35.237 12.708-63.54zm141.375 0l12.708 63.54-64.598-35.238 51.89-28.303zM247 429.903v28.203h-51.705L247 429.904zm18 0l51.705 28.203H265v-28.203z"
                  />
                </svg>
              </div>
              <div>
                <h3 className="hm-service-title">Drilling Rig Equipment</h3>
                <p>
                  Equip your rig with our top-quality equipment, built to
                  withstand the demands of heavy-duty drilling operations. We
                  offer comprehensive solutions to meet all your rig needs.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="flipInX" animateOnce={true} delay={1500}>
            <div className="hm-service-body">
              <div className="hm-service-icon">
                <svg
                  width="50px"
                  height="50px"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="hm-service-icon"
                    d="M291.17 16.975c-5.923.122-11.645 2.246-16.06 7.398-17.652 20.61-13.472 36.05-10.667 42.856 16.607-51.264 33.81-5.23 55.442-25.076 8.615-7.904-10.947-25.546-28.715-25.18zM208 67.107c-19.994.235-33.533 26.42-25.31 33.495 22.252 19.138 32.473-26.055 58.884-4.803-6.212-13.538-13.58-28.927-33.574-28.693zm93.832 8.967c-12.564.318-21.72 9.49-29.832 17.832 32-11.19 26.51 34.82 53.883 24.23 10.118-3.914 6.12-33.12-12.643-40.032-4.104-1.512-7.89-2.12-11.408-2.03zM247 106.107v32h-30.38l-6.4 32H199v18h7.62l-15.417 77.09-1.594.91H176v18h11.422l-34.8 174H128v18h256v-18h-24.62l-34.802-174H336v-18h-13.61l-1.593-.91-15.418-77.09H313v-18h-11.22l-6.4-32H265v-32h-18zm-15.62 50H247v14h-18.42l2.8-14zm33.62 0h15.62l2.8 14H265v-14zm-40.02 32H247v34.92l-26.03-14.875 4.01-20.045zm40.02 0h22.02l4.01 20.045L265 223.027v-34.92zm-47.752 38.65l20.61 11.778-25.928 14.82 5.318-26.597zm77.504 0l5.318 26.597-25.93-14.82 20.612-11.776zM247 254.046v12.062h-21.11L247 254.045zm18 0l21.11 12.062H265v-12.062zm-59.22 30.062H247v47.846l-47.615-15.87 6.394-31.976zm59.22 0h41.22l6.407 32.028L265 332.61v-48.503zm-69.172 49.762l33.38 11.126-38.253 13.234 4.873-24.36zm120.36.08l4.515 22.57-35.525-11.84 31.01-10.73zM265 356.925l48.54 16.18L265 399.58v-42.656zm-18 .963v41.692l-46.77-25.512L247 357.89zm-61.688 28.55l51.893 28.302-64.6 35.237 12.708-63.54zm141.375 0l12.708 63.54-64.598-35.238 51.89-28.303zM247 429.903v28.203h-51.705L247 429.904zm18 0l51.705 28.203H265v-28.203z"
                  />
                </svg>
              </div>
              <div>
                <h3 className="hm-service-title">Bentonite Powder</h3>
                <p>
                  Our Bentonite Powder is perfect for stabilizing boreholes and
                  improving drilling fluid properties. It’s a key component in
                  ensuring smooth and efficient drilling processes.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="flipInX" animateOnce={true} delay={2000}>
            <div className="hm-service-body">
              <div className="hm-service-icon">
                <svg width="50px" height="50px" viewBox="0 0 512 512">
                  <g>
                    <g>
                      <g>
                        <rect x="480" y="480" width="16" height="16" />
                        <path
                          d="M496,176v-16H336v16h16v72h-16v16h16v72h-24.8L296.888,48H312V0h-96v48h15.112L200.8,336h-13.08L63.664,188.68
                    C61.976,172.608,48.512,160,32,160c-17.648,0-32,14.352-32,32c0,14.872,10.232,27.288,24,30.864V480H0v16h464v-16h-16v-80h48v-64
                    h-16v-72h16v-16h-16v-72H496z M368,176h96v72h-96V176z M368,264h96v72h-96V264z M310.624,331.312L243.312,264h60.224
                    L310.624,331.312z M301.096,240.816L249.96,184h45.152L301.096,240.816z M242.992,88l4.208-40h33.6l4.208,40H242.992z
                     M286.696,104l5.544,52.68L255.368,104H286.696z M280.632,168h-46.064l6.024-57.208L280.632,168z M232,16h64v16h-0.8h-62.4H232
                    V16z M232.424,188.424L286.04,248h-59.888L232.424,188.424z M224.104,267.416L292.688,336H216.88L224.104,267.416z M166.8,336
                    h-34.592l-11-16h32.12L166.8,336z M115.968,275.632L139.856,304h-29.648l-3.536-5.144L115.968,275.632z M104.288,261.752
                    l-8.504,21.256l-29.08-42.296l31.08,13.32L104.288,261.752z M59.344,208.384l15.512,18.424l-23.04-9.872
                    C54.808,214.544,57.36,211.68,59.344,208.384z M16,192c0-8.824,7.176-16,16-16s16,7.176,16,16c0,8.824-7.176,16-16,16
                    S16,200.824,16,192z M104,480H40V230.12l48.64,70.744l-0.064,0.168l0.248,0.096L112.792,336H56v64h48V480z M184,448h-8v16h8v16
                    h-64v-16h40v-16h-40v-48h64V448z M352,480H200v-80h152V480z M432,448h-8v16h8v16h-64v-16h40v-16h-40v-48h64V448z M480,384H72v-32
                    h127.12h129.76H480V384z"
                        />
                        <rect x="384" y="192" width="16" height="16" />
                        <rect x="416" y="192" width="16" height="16" />
                        <rect x="432" y="304" width="16" height="16" />
                        <rect x="400" y="304" width="16" height="16" />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div>
                <h3 className="hm-service-title">Bulk Cement Supply</h3>
                <p>
                  We offer bulk cement supply to support your construction and
                  drilling projects. Our high-quality cement ensures strength
                  and durability in every application.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="flipInX" animateOnce={true} delay={2500}>
            <div className="hm-service-body">
              <div className="hm-service-icon">
                <svg
                  width="50px"
                  height="50px"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="hm-service-icon"
                    d="M291.17 16.975c-5.923.122-11.645 2.246-16.06 7.398-17.652 20.61-13.472 36.05-10.667 42.856 16.607-51.264 33.81-5.23 55.442-25.076 8.615-7.904-10.947-25.546-28.715-25.18zM208 67.107c-19.994.235-33.533 26.42-25.31 33.495 22.252 19.138 32.473-26.055 58.884-4.803-6.212-13.538-13.58-28.927-33.574-28.693zm93.832 8.967c-12.564.318-21.72 9.49-29.832 17.832 32-11.19 26.51 34.82 53.883 24.23 10.118-3.914 6.12-33.12-12.643-40.032-4.104-1.512-7.89-2.12-11.408-2.03zM247 106.107v32h-30.38l-6.4 32H199v18h7.62l-15.417 77.09-1.594.91H176v18h11.422l-34.8 174H128v18h256v-18h-24.62l-34.802-174H336v-18h-13.61l-1.593-.91-15.418-77.09H313v-18h-11.22l-6.4-32H265v-32h-18zm-15.62 50H247v14h-18.42l2.8-14zm33.62 0h15.62l2.8 14H265v-14zm-40.02 32H247v34.92l-26.03-14.875 4.01-20.045zm40.02 0h22.02l4.01 20.045L265 223.027v-34.92zm-47.752 38.65l20.61 11.778-25.928 14.82 5.318-26.597zm77.504 0l5.318 26.597-25.93-14.82 20.612-11.776zM247 254.046v12.062h-21.11L247 254.045zm18 0l21.11 12.062H265v-12.062zm-59.22 30.062H247v47.846l-47.615-15.87 6.394-31.976zm59.22 0h41.22l6.407 32.028L265 332.61v-48.503zm-69.172 49.762l33.38 11.126-38.253 13.234 4.873-24.36zm120.36.08l4.515 22.57-35.525-11.84 31.01-10.73zM265 356.925l48.54 16.18L265 399.58v-42.656zm-18 .963v41.692l-46.77-25.512L247 357.89zm-61.688 28.55l51.893 28.302-64.6 35.237 12.708-63.54zm141.375 0l12.708 63.54-64.598-35.238 51.89-28.303zM247 429.903v28.203h-51.705L247 429.904zm18 0l51.705 28.203H265v-28.203z"
                  />
                </svg>
              </div>
              <div>
                <h3 className="hm-service-title">Caustic Soda Flakes</h3>
                <p>
                  Our Caustic Soda Flakes are essential for a variety of
                  industrial processes, including pH control in drilling fluids.
                  We provide high-purity flakes for reliable performance.
                </p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  } else {
    return (
      <div className="hm-abt-contain">
        <div>
          <h2 style={{ textAlign: "center" }}>
            Our <span className="fg-shade-one-dark">Services</span>
          </h2>
        </div>
        <div className="hm-service-container">
          <div className="hm-service-body">
            <div className="hm-service-icon">
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="hm-service-icon"
                  d="M291.17 16.975c-5.923.122-11.645 2.246-16.06 7.398-17.652 20.61-13.472 36.05-10.667 42.856 16.607-51.264 33.81-5.23 55.442-25.076 8.615-7.904-10.947-25.546-28.715-25.18zM208 67.107c-19.994.235-33.533 26.42-25.31 33.495 22.252 19.138 32.473-26.055 58.884-4.803-6.212-13.538-13.58-28.927-33.574-28.693zm93.832 8.967c-12.564.318-21.72 9.49-29.832 17.832 32-11.19 26.51 34.82 53.883 24.23 10.118-3.914 6.12-33.12-12.643-40.032-4.104-1.512-7.89-2.12-11.408-2.03zM247 106.107v32h-30.38l-6.4 32H199v18h7.62l-15.417 77.09-1.594.91H176v18h11.422l-34.8 174H128v18h256v-18h-24.62l-34.802-174H336v-18h-13.61l-1.593-.91-15.418-77.09H313v-18h-11.22l-6.4-32H265v-32h-18zm-15.62 50H247v14h-18.42l2.8-14zm33.62 0h15.62l2.8 14H265v-14zm-40.02 32H247v34.92l-26.03-14.875 4.01-20.045zm40.02 0h22.02l4.01 20.045L265 223.027v-34.92zm-47.752 38.65l20.61 11.778-25.928 14.82 5.318-26.597zm77.504 0l5.318 26.597-25.93-14.82 20.612-11.776zM247 254.046v12.062h-21.11L247 254.045zm18 0l21.11 12.062H265v-12.062zm-59.22 30.062H247v47.846l-47.615-15.87 6.394-31.976zm59.22 0h41.22l6.407 32.028L265 332.61v-48.503zm-69.172 49.762l33.38 11.126-38.253 13.234 4.873-24.36zm120.36.08l4.515 22.57-35.525-11.84 31.01-10.73zM265 356.925l48.54 16.18L265 399.58v-42.656zm-18 .963v41.692l-46.77-25.512L247 357.89zm-61.688 28.55l51.893 28.302-64.6 35.237 12.708-63.54zm141.375 0l12.708 63.54-64.598-35.238 51.89-28.303zM247 429.903v28.203h-51.705L247 429.904zm18 0l51.705 28.203H265v-28.203z"
                />
              </svg>
            </div>
            <div>
              <h3 className="hm-service-title">Drilling Equipment</h3>
              <p>
                We provide high-performance drilling equipment designed to
                optimize your operations. Our reliable tools ensure efficiency
                and safety in the toughest environments.
              </p>
            </div>
          </div>
          <div className="hm-service-body">
            <div className="hm-service-icon">
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 512 512"
                version="1.1"
                id="Layer_1"
              >
                <g>
                  <g>
                    <g>
                      <rect x="480" y="480" width="16" height="16" />
                      <path
                        d="M496,176v-16H336v16h16v72h-16v16h16v72h-24.8L296.888,48H312V0h-96v48h15.112L200.8,336h-13.08L63.664,188.68
                    C61.976,172.608,48.512,160,32,160c-17.648,0-32,14.352-32,32c0,14.872,10.232,27.288,24,30.864V480H0v16h464v-16h-16v-80h48v-64
                    h-16v-72h16v-16h-16v-72H496z M368,176h96v72h-96V176z M368,264h96v72h-96V264z M310.624,331.312L243.312,264h60.224
                    L310.624,331.312z M301.096,240.816L249.96,184h45.152L301.096,240.816z M242.992,88l4.208-40h33.6l4.208,40H242.992z
                     M286.696,104l5.544,52.68L255.368,104H286.696z M280.632,168h-46.064l6.024-57.208L280.632,168z M232,16h64v16h-0.8h-62.4H232
                    V16z M232.424,188.424L286.04,248h-59.888L232.424,188.424z M224.104,267.416L292.688,336H216.88L224.104,267.416z M166.8,336
                    h-34.592l-11-16h32.12L166.8,336z M115.968,275.632L139.856,304h-29.648l-3.536-5.144L115.968,275.632z M104.288,261.752
                    l-8.504,21.256l-29.08-42.296l31.08,13.32L104.288,261.752z M59.344,208.384l15.512,18.424l-23.04-9.872
                    C54.808,214.544,57.36,211.68,59.344,208.384z M16,192c0-8.824,7.176-16,16-16s16,7.176,16,16c0,8.824-7.176,16-16,16
                    S16,200.824,16,192z M104,480H40V230.12l48.64,70.744l-0.064,0.168l0.248,0.096L112.792,336H56v64h48V480z M184,448h-8v16h8v16
                    h-64v-16h40v-16h-40v-48h64V448z M352,480H200v-80h152V480z M432,448h-8v16h8v16h-64v-16h40v-16h-40v-48h64V448z M480,384H72v-32
                    h127.12h129.76H480V384z"
                      />
                      <rect x="384" y="192" width="16" height="16" />
                      <rect x="416" y="192" width="16" height="16" />
                      <rect x="432" y="304" width="16" height="16" />
                      <rect x="400" y="304" width="16" height="16" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div>
              <h3 className="hm-service-title">Mud Pump & Parts</h3>
              <p>
                Our durable mud pumps and parts are engineered for maximum
                reliability and longevity. They are essential for maintaining
                optimal drilling fluid circulation.
              </p>
            </div>
          </div>
          <div className="hm-service-body">
            <div className="hm-service-icon">
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="hm-service-icon"
                  d="M291.17 16.975c-5.923.122-11.645 2.246-16.06 7.398-17.652 20.61-13.472 36.05-10.667 42.856 16.607-51.264 33.81-5.23 55.442-25.076 8.615-7.904-10.947-25.546-28.715-25.18zM208 67.107c-19.994.235-33.533 26.42-25.31 33.495 22.252 19.138 32.473-26.055 58.884-4.803-6.212-13.538-13.58-28.927-33.574-28.693zm93.832 8.967c-12.564.318-21.72 9.49-29.832 17.832 32-11.19 26.51 34.82 53.883 24.23 10.118-3.914 6.12-33.12-12.643-40.032-4.104-1.512-7.89-2.12-11.408-2.03zM247 106.107v32h-30.38l-6.4 32H199v18h7.62l-15.417 77.09-1.594.91H176v18h11.422l-34.8 174H128v18h256v-18h-24.62l-34.802-174H336v-18h-13.61l-1.593-.91-15.418-77.09H313v-18h-11.22l-6.4-32H265v-32h-18zm-15.62 50H247v14h-18.42l2.8-14zm33.62 0h15.62l2.8 14H265v-14zm-40.02 32H247v34.92l-26.03-14.875 4.01-20.045zm40.02 0h22.02l4.01 20.045L265 223.027v-34.92zm-47.752 38.65l20.61 11.778-25.928 14.82 5.318-26.597zm77.504 0l5.318 26.597-25.93-14.82 20.612-11.776zM247 254.046v12.062h-21.11L247 254.045zm18 0l21.11 12.062H265v-12.062zm-59.22 30.062H247v47.846l-47.615-15.87 6.394-31.976zm59.22 0h41.22l6.407 32.028L265 332.61v-48.503zm-69.172 49.762l33.38 11.126-38.253 13.234 4.873-24.36zm120.36.08l4.515 22.57-35.525-11.84 31.01-10.73zM265 356.925l48.54 16.18L265 399.58v-42.656zm-18 .963v41.692l-46.77-25.512L247 357.89zm-61.688 28.55l51.893 28.302-64.6 35.237 12.708-63.54zm141.375 0l12.708 63.54-64.598-35.238 51.89-28.303zM247 429.903v28.203h-51.705L247 429.904zm18 0l51.705 28.203H265v-28.203z"
                />
              </svg>
            </div>
            <div>
              <h3 className="hm-service-title">Drilling Rig Equipment</h3>
              <p>
                Equip your rig with our top-quality equipment, built to
                withstand the demands of heavy-duty drilling operations. We
                offer comprehensive solutions to meet all your rig needs.
              </p>
            </div>
          </div>
          <div className="hm-service-body">
            <div className="hm-service-icon">
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="hm-service-icon"
                  d="M291.17 16.975c-5.923.122-11.645 2.246-16.06 7.398-17.652 20.61-13.472 36.05-10.667 42.856 16.607-51.264 33.81-5.23 55.442-25.076 8.615-7.904-10.947-25.546-28.715-25.18zM208 67.107c-19.994.235-33.533 26.42-25.31 33.495 22.252 19.138 32.473-26.055 58.884-4.803-6.212-13.538-13.58-28.927-33.574-28.693zm93.832 8.967c-12.564.318-21.72 9.49-29.832 17.832 32-11.19 26.51 34.82 53.883 24.23 10.118-3.914 6.12-33.12-12.643-40.032-4.104-1.512-7.89-2.12-11.408-2.03zM247 106.107v32h-30.38l-6.4 32H199v18h7.62l-15.417 77.09-1.594.91H176v18h11.422l-34.8 174H128v18h256v-18h-24.62l-34.802-174H336v-18h-13.61l-1.593-.91-15.418-77.09H313v-18h-11.22l-6.4-32H265v-32h-18zm-15.62 50H247v14h-18.42l2.8-14zm33.62 0h15.62l2.8 14H265v-14zm-40.02 32H247v34.92l-26.03-14.875 4.01-20.045zm40.02 0h22.02l4.01 20.045L265 223.027v-34.92zm-47.752 38.65l20.61 11.778-25.928 14.82 5.318-26.597zm77.504 0l5.318 26.597-25.93-14.82 20.612-11.776zM247 254.046v12.062h-21.11L247 254.045zm18 0l21.11 12.062H265v-12.062zm-59.22 30.062H247v47.846l-47.615-15.87 6.394-31.976zm59.22 0h41.22l6.407 32.028L265 332.61v-48.503zm-69.172 49.762l33.38 11.126-38.253 13.234 4.873-24.36zm120.36.08l4.515 22.57-35.525-11.84 31.01-10.73zM265 356.925l48.54 16.18L265 399.58v-42.656zm-18 .963v41.692l-46.77-25.512L247 357.89zm-61.688 28.55l51.893 28.302-64.6 35.237 12.708-63.54zm141.375 0l12.708 63.54-64.598-35.238 51.89-28.303zM247 429.903v28.203h-51.705L247 429.904zm18 0l51.705 28.203H265v-28.203z"
                />
              </svg>
            </div>
            <div>
              <h3 className="hm-service-title">Bentonite Powder</h3>
              <p>
                Our Bentonite Powder is perfect for stabilizing boreholes and
                improving drilling fluid properties. It’s a key component in
                ensuring smooth and efficient drilling processes.
              </p>
            </div>
          </div>
          <div className="hm-service-body">
            <div className="hm-service-icon">
              <svg width="50px" height="50px" viewBox="0 0 512 512">
                <g>
                  <g>
                    <g>
                      <rect x="480" y="480" width="16" height="16" />
                      <path
                        d="M496,176v-16H336v16h16v72h-16v16h16v72h-24.8L296.888,48H312V0h-96v48h15.112L200.8,336h-13.08L63.664,188.68
                    C61.976,172.608,48.512,160,32,160c-17.648,0-32,14.352-32,32c0,14.872,10.232,27.288,24,30.864V480H0v16h464v-16h-16v-80h48v-64
                    h-16v-72h16v-16h-16v-72H496z M368,176h96v72h-96V176z M368,264h96v72h-96V264z M310.624,331.312L243.312,264h60.224
                    L310.624,331.312z M301.096,240.816L249.96,184h45.152L301.096,240.816z M242.992,88l4.208-40h33.6l4.208,40H242.992z
                     M286.696,104l5.544,52.68L255.368,104H286.696z M280.632,168h-46.064l6.024-57.208L280.632,168z M232,16h64v16h-0.8h-62.4H232
                    V16z M232.424,188.424L286.04,248h-59.888L232.424,188.424z M224.104,267.416L292.688,336H216.88L224.104,267.416z M166.8,336
                    h-34.592l-11-16h32.12L166.8,336z M115.968,275.632L139.856,304h-29.648l-3.536-5.144L115.968,275.632z M104.288,261.752
                    l-8.504,21.256l-29.08-42.296l31.08,13.32L104.288,261.752z M59.344,208.384l15.512,18.424l-23.04-9.872
                    C54.808,214.544,57.36,211.68,59.344,208.384z M16,192c0-8.824,7.176-16,16-16s16,7.176,16,16c0,8.824-7.176,16-16,16
                    S16,200.824,16,192z M104,480H40V230.12l48.64,70.744l-0.064,0.168l0.248,0.096L112.792,336H56v64h48V480z M184,448h-8v16h8v16
                    h-64v-16h40v-16h-40v-48h64V448z M352,480H200v-80h152V480z M432,448h-8v16h8v16h-64v-16h40v-16h-40v-48h64V448z M480,384H72v-32
                    h127.12h129.76H480V384z"
                      />
                      <rect x="384" y="192" width="16" height="16" />
                      <rect x="416" y="192" width="16" height="16" />
                      <rect x="432" y="304" width="16" height="16" />
                      <rect x="400" y="304" width="16" height="16" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div>
              <h3 className="hm-service-title">Bulk Cement Supply</h3>
              <p>
                We offer bulk cement supply to support your construction and
                drilling projects. Our high-quality cement ensures strength and
                durability in every application.
              </p>
            </div>
          </div>
          <div className="hm-service-body">
            <div className="hm-service-icon">
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className="hm-service-icon"
                  d="M291.17 16.975c-5.923.122-11.645 2.246-16.06 7.398-17.652 20.61-13.472 36.05-10.667 42.856 16.607-51.264 33.81-5.23 55.442-25.076 8.615-7.904-10.947-25.546-28.715-25.18zM208 67.107c-19.994.235-33.533 26.42-25.31 33.495 22.252 19.138 32.473-26.055 58.884-4.803-6.212-13.538-13.58-28.927-33.574-28.693zm93.832 8.967c-12.564.318-21.72 9.49-29.832 17.832 32-11.19 26.51 34.82 53.883 24.23 10.118-3.914 6.12-33.12-12.643-40.032-4.104-1.512-7.89-2.12-11.408-2.03zM247 106.107v32h-30.38l-6.4 32H199v18h7.62l-15.417 77.09-1.594.91H176v18h11.422l-34.8 174H128v18h256v-18h-24.62l-34.802-174H336v-18h-13.61l-1.593-.91-15.418-77.09H313v-18h-11.22l-6.4-32H265v-32h-18zm-15.62 50H247v14h-18.42l2.8-14zm33.62 0h15.62l2.8 14H265v-14zm-40.02 32H247v34.92l-26.03-14.875 4.01-20.045zm40.02 0h22.02l4.01 20.045L265 223.027v-34.92zm-47.752 38.65l20.61 11.778-25.928 14.82 5.318-26.597zm77.504 0l5.318 26.597-25.93-14.82 20.612-11.776zM247 254.046v12.062h-21.11L247 254.045zm18 0l21.11 12.062H265v-12.062zm-59.22 30.062H247v47.846l-47.615-15.87 6.394-31.976zm59.22 0h41.22l6.407 32.028L265 332.61v-48.503zm-69.172 49.762l33.38 11.126-38.253 13.234 4.873-24.36zm120.36.08l4.515 22.57-35.525-11.84 31.01-10.73zM265 356.925l48.54 16.18L265 399.58v-42.656zm-18 .963v41.692l-46.77-25.512L247 357.89zm-61.688 28.55l51.893 28.302-64.6 35.237 12.708-63.54zm141.375 0l12.708 63.54-64.598-35.238 51.89-28.303zM247 429.903v28.203h-51.705L247 429.904zm18 0l51.705 28.203H265v-28.203z"
                />
              </svg>
            </div>
            <div>
              <h3 className="hm-service-title">Caustic Soda Flakes</h3>
              <p>
                Our Caustic Soda Flakes are essential for a variety of
                industrial processes, including pH control in drilling fluids.
                We provide high-purity flakes for reliable performance.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ServicesComp;
