import React from "react";
import ProjectComp from "../projects/projectComp";
import Feedback from "../components/feedback";
import Quote from "../components/quote";

const Projects = () => {
  return (
    <div>
      <div className="serv-top-level">
        <h2 className="fg-shade-one">Artino Projects</h2>
        <p>
          Home / <span className="fg-shade-one">Projects</span>
        </p>
      </div>
      <ProjectComp allowAnimation={false}></ProjectComp>
      <div style={{backgroundColor:'whitesmoke'}}>
        <Quote></Quote>
      </div>
      {/* Feedback */}
      <div style={{ backgroundColor: "white" }}>
        <Feedback></Feedback>
      </div>
    </div>
  );
};

export default Projects;
