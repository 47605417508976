import React, { useState } from "react";
import { BiSolidTimer } from "react-icons/bi";
import "../styles/header.css";
import { MdCall, MdEmail, MdLocationPin } from "react-icons/md";
import { BsCircleFill } from "react-icons/bs";
import Hamburger from "hamburger-react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const routeTo = (page) => {
    navigate(page);
  };
  return (
    <div className="hd-parent">
      {/* Top Layer */}
      <div className="hd-space-between bg-shade-one-dark">
        <p className="mont-sm">Welcome to Artino Projects Limited</p>
        <div className="flex-left flex-gap-mini">
          <BiSolidTimer style={{ fontSize: "25px" }}></BiSolidTimer>
          <p>Monday - Saturday 9:00am - 5:00pm</p>
        </div>
      </div>
      {/* Mid Layer */}
      <div className="hd-mid-space">
        <img
          src={require("../assets/images/logo_full.png")}
          alt="Artino Projects Limited"
          className="logo"
        ></img>
        <div className="flex-left flex-wrap">
          <div className="flex-center">
            <div className="hd-contact-div">
              <MdLocationPin></MdLocationPin>
            </div>
            <div>
              <p className="mont-bold">Meet Us</p>
              <p>53 East West Road</p>
              <p>Portharcourt, Rivers State, Nigeria</p>
            </div>
          </div>
          <div className="flex-center">
            <div className="hd-contact-div">
              <MdCall></MdCall>
            </div>
            <div>
              <p className="mont-bold">Contact Us</p>
              <p>
                <a className="hd-contact" href="tel:+2349137312468">
                  +234 913 731 2468
                </a>
              </p>
              <p>
                <a className="hd-contact" href="tel:+2347033805477">
                  +234 703 380 5477
                </a>
              </p>
            </div>
          </div>
          <div className="flex-center">
            <div className="hd-contact-div">
              <MdEmail></MdEmail>
            </div>
            <div>
              <p className="mont-bold">Mail Us</p>
              <p>
                <a className="hd-contact" href="mailto:info@artinoprojects.com">
                  info@artinoprojects.com
                </a>
              </p>
              <p>
                <a
                  className="hd-contact"
                  href="mailto:projects@artinoprojects.com"
                >
                  projects@artinoprojects.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Menu Layer - Webpage*/}
      <div className="hide-mobile">
        <div className="hd-menu-bar">
          <div className="hd-menu-main bg-shade-one-dark">
            <div className="hd-menu">
              <div className="hd-menu-con" onClick={() => routeTo("/")}>
                <p>Home</p>
                <div className="hd-menu-line"></div>
              </div>
              <p>
                <BsCircleFill style={{ fontSize: "3px" }}></BsCircleFill>
              </p>
              <div className="hd-menu-con" onClick={() => routeTo("/about")}>
                <p>About</p>
                <div className="hd-menu-line"></div>
              </div>
              <p>
                <BsCircleFill style={{ fontSize: "3px" }}></BsCircleFill>
              </p>
              <div className="hd-menu-con" onClick={() => routeTo("/services")}>
                <p>Services</p>
                <div className="hd-menu-line"></div>
              </div>
              <p>
                <BsCircleFill style={{ fontSize: "3px" }}></BsCircleFill>
              </p>
              <div className="hd-menu-con" onClick={() => routeTo("/projects")}>
                <p>Projects</p>
                <div className="hd-menu-line"></div>
              </div>
            </div>
            <div className="hd-quote">
              <a href="#getquote">Get Quote</a>
            </div>
          </div>
        </div>
      </div>
      {/* Menu Layer - Mobilepage*/}
      <div className="hide-desktop">
        <div className="hd-menu-bar-mobile">
          <div className="hd-menu-main-mobile bg-shade-one-dark">
            <div className="hd-quote">
              <a href="#getquote">Get Quote</a>
            </div>
            <Hamburger
              toggled={isOpen}
              size={25}
              toggle={setOpen}
              className="hamburger"
            />
          </div>
        </div>
      </div>
      <div className="hide-desktop">
        <div
          className={
            isOpen ? "hd-menu-mobile" : "hd-menu-mobile hd-menu-mobile-hide"
          }
        >
          <div
            className={isOpen ? "hd-menu-con" : "hide"}
            onClick={() => routeTo("/")}
          >
            <p>Home</p>
            <div className="hd-menu-line"></div>
          </div>
          <p className={isOpen ? "" : "hide"}>
            <BsCircleFill style={{ fontSize: "3px" }}></BsCircleFill>
          </p>
          <div
            className={isOpen ? "hd-menu-con" : "hide"}
            onClick={() => routeTo("/about")}
          >
            <p>About</p>
            <div className="hd-menu-line"></div>
          </div>
          <p className={isOpen ? "" : "hide"}>
            <BsCircleFill style={{ fontSize: "3px" }}></BsCircleFill>
          </p>
          <div
            className={isOpen ? "hd-menu-con" : "hide"}
            onClick={() => routeTo("/services")}
          >
            <p>Services</p>
            <div className="hd-menu-line"></div>
          </div>
          <p className={isOpen ? "" : "hide"}>
            <BsCircleFill style={{ fontSize: "3px" }}></BsCircleFill>
          </p>
          <div
            className={isOpen ? "hd-menu-con" : "hide"}
            onClick={() => routeTo("/projects")}
          >
            <p>Projects</p>
            <div className="hd-menu-line"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
