import React from "react";
import "../styles/corevalues.css";

const CoreValues = () => {
  return (
    <div className="core-container">
      <div className="core-header">
        <br></br>
        <h2>
          Our <span className="fg-shade-one-dark">Core Values</span>
        </h2>
        <p>
          Our values guide everything we do, from the way we interact with our
          clients to the way we work with our team members.
        </p>
      </div>
      <div className="core-details">
        <div className="ripple">
          <h3>Integrity</h3>
          <p>
            At Artino Projects Limited, we are committed to conducting our
            business with the highest level of integrity and earning the trust
            of our clients. Our code of integrity contains our guilding ethical
            principles and sets forth the behavior we expect of all our
            employees, directors and officers, as well as our business partners.
          </p>
        </div>
        <div className="ripple">
          <h3>Excellence</h3>
          <p>
            We strive for the highest quality in everything we do. We are always
            looking for new ways to improve our processes and our services.
          </p>
        </div>
        <div className="ripple">
          <h3>Innovation</h3>
          <p>
            We are open to new ideas and ways of doing things. We believe that
            innovation is essential for success, and we are always looking for
            ways to stay ahead of the competition.
          </p>
        </div>
        <div className="ripple">
          <h3>Teamwork</h3>
          <p>
            We believe that a collaborative and supportive work environment is
            essential for success. We encourage our team members to learn and
            grow, and we value their contributions to our company.
          </p>
        </div>
        <div className="ripple">
          <h3>Communication</h3>
          <p>
            We believe in clear and concise communication. We always keep our
            clients and team members informed, and we make sure that everyone is
            on the same page.
          </p>
        </div>
        <div className="ripple">
          <h3>Customer Focus</h3>
          <p>
            We put the customer first and always strive to meet their
            expectations. We listen to their feedback and we make sure that we
            are providing them with the best possible service.
          </p>
        </div>
        <div className="ripple">
          <h3>Continuous Improvement</h3>
          <p>
            We are always looking for ways to improve our processes and our
            services. We never settle for the status quo, and we are always
            striving to be better.
          </p>
        </div>
        <div className="ripple">
          <h3>Learning and Development</h3>
          <p>
            We encourage our team members to learn and grow. We provide them
            with the resources they need to develop their
          </p>
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
