import React from "react";
import "../styles/service.css";
import ServicesComp from "../service/servicesComp";
import Feedback from "../components/feedback";
import Quote from "../components/quote";

const Services = () => {
  return (
    <div>
      <div className="serv-top-level">
        <h2 className="fg-shade-one">Artino Services</h2>
        <p>
          Home / <span className="fg-shade-one">Services</span>
        </p>
      </div>
      <ServicesComp allowAnimation={false}></ServicesComp>
      <Quote></Quote>
      <div style={{backgroundColor:'whitesmoke'}}>
        <Feedback></Feedback>
      </div>
    </div>
  );
};

export default Services;
