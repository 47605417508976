import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/home.css";
import "../styles/feedback.css";
import { BsPersonCircle } from "react-icons/bs";

const destinations = [
  {
    name: "Mr. Liu (CHCI international)",
    tours:
      "Artino Projects limited is very reliable and competent in material supply and distribution. I have worked with them and can say they are ever ready and surpassed expectation whenever I work with them.",
  },
  {
    name: "Hamza Isah",
    tours:
      "I can confidently confirm that Artino Projects is a team of serious minded professionals that deliver topnotch services.",
  },
  {
    name: "Engr. Yusuf",
    tours:
      "Artino Projects will always have my endorsement any day because of their professional approach to jobs. I have proven their competence over and over for all projects I have had with them.",
  },
];

const Feedback = () => {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };
  return (
    <div className="popular-destinations">
      <h2 style={{ textAlign: "center" }}>
        Client <span className="fg-shade-one-dark">Reviews</span>
      </h2>
      <p className="additional-info">
        Hear what our client say about our projects
      </p>
      <div className="review-slide">
        <Slider {...settings}>
          {destinations.map((destinations, index) => (
            <div key={index} className="review-flex">
              <div>
                <BsPersonCircle
                  style={{ fontSize: "80px", color: "#8b0000" }}
                ></BsPersonCircle>
              </div>
              <div className="review-contact">
                <h4 style={{ color: "#8b0000" }}>
                  {destinations.name}
                </h4>
                <p>{destinations.tours}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Feedback;
