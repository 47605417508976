import axios from "axios";
const url =
  "https://script.google.com/macros/s/AKfycbzLuYjr1sZ5L9iEClsprNo6_SwFbO11ncqUb2EtlxudZEUFAyIBVxsLxJpv4aqQZ5xY/exec";

export async function GetQuote(data) {
  try {
    await axios.post(`${url}?path=getquote`, JSON.stringify(data));
  } catch (error) {
    console.log(error);
  }
  console.log(data);
}

export async function ContactUs(data) {
    try {
      await axios.post(`${url}?path=contactus`, JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
    console.log(data);
  }
