import React from "react";
import "../styles/about.css";
import CoreValues from "../components/corevalues";
import ScrollAnimation from "react-animate-on-scroll";
import Feedback from "../components/feedback";

const About = () => {
  return (
    <div>
      <div className="abt-top-level">
        <h2 className="fg-shade-one">About Artino</h2>
        <p>
          Home / <span className="fg-shade-one">About us</span>
        </p>
      </div>
      <div className="about-background">
        <div className="about-background-content">
          <p>
            Artino Projects Limited is a leader in delivering world-class
            solutions in the oil, gas, and energy sectors. We specialize in the
            distribution of essential industrial materials, including Cement,
            Caustic Soda, and Bentonite Powder, and offer top-quality drilling
            equipment. Additionally, we are renowned contractors in road
            construction, delivering durable and efficient infrastructure.
          </p>
        </div>
      </div>
      {/* Artino Way */}
      <div>
        <div className="abt-abt-contain">
          <div>
            <ScrollAnimation animateIn="fadeIn" animateOnce="true">
              <h2 style={{ textAlign: "center" }}>
                The <span className="fg-shade-one-dark">Artino Way</span>
              </h2>
            </ScrollAnimation>
          </div>
          <div className="abt-service-container">
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={0}>
              <div className="abt-service-body">
                <div className="abt-service-icon">
                  <svg
                    width="50px"
                    height="50px"
                    viewBox="0 0 1024 1024"
                    fill="#000000"
                    class="icon"
                    version="1.1"
                  >
                    <path
                      d="M392.07 657.084a7.8 7.8 0 0 1-3.856-1c-81.426-44.952-132.016-130.796-132.016-224.028 0-141.054 114.762-255.816 255.818-255.816a7.988 7.988 0 0 1 7.994 7.994 7.99 7.99 0 0 1-7.994 7.994c-132.25 0-239.83 107.586-239.83 239.828 0 87.406 47.42 167.88 123.74 210.038a7.984 7.984 0 0 1 3.138 10.868 8 8 0 0 1-6.994 4.122z"
                      fill=""
                    />
                    <path
                      d="M631.946 657.084a7.996 7.996 0 0 1-6.994-4.124 7.988 7.988 0 0 1 3.136-10.868c76.32-42.156 123.74-122.63 123.74-210.038 0-132.242-107.58-239.828-239.812-239.828a7.99 7.99 0 0 1-7.996-7.994 7.99 7.99 0 0 1 7.996-7.994c141.054 0 255.8 114.762 255.8 255.816 0 93.23-50.588 179.076-132.014 224.028a7.786 7.786 0 0 1-3.856 1.002zM392.1 783.804a7.988 7.988 0 0 1-7.994-7.992v-126.722c0-4.418 3.576-7.994 7.994-7.994s7.994 3.576 7.994 7.994v126.722a7.988 7.988 0 0 1-7.994 7.992z"
                      fill=""
                    />
                    <path
                      d="M631.914 783.804a7.988 7.988 0 0 1-7.994-7.992v-126.536a7.988 7.988 0 0 1 7.994-7.994 7.99 7.99 0 0 1 7.994 7.994v126.536a7.988 7.988 0 0 1-7.994 7.992z"
                      fill=""
                    />
                    <path
                      d="M631.914 687.876H392.1a7.99 7.99 0 0 1-7.994-7.996 7.988 7.988 0 0 1 7.994-7.992h239.814a7.988 7.988 0 0 1 7.994 7.992 7.992 7.992 0 0 1-7.994 7.996zM599.936 815.784h-175.858a7.99 7.99 0 0 1-5.652-2.342l-31.978-31.976a7.992 7.992 0 1 1 11.304-11.304l29.634 29.634h169.24l29.634-29.634a7.988 7.988 0 0 1 11.304 0 7.988 7.988 0 0 1 0 11.304l-31.978 31.976a7.98 7.98 0 0 1-5.65 2.342zM376.112 735.84a7.992 7.992 0 0 1-0.468-15.974l271.79-15.988c4.358-0.14 8.182 3.108 8.448 7.51a7.998 7.998 0 0 1-7.51 8.448l-271.792 15.988c-0.156 0.016-0.312 0.016-0.468 0.016zM376.112 767.816a7.99 7.99 0 0 1-7.978-7.526 8 8 0 0 1 7.51-8.448l271.79-15.988c4.358-0.312 8.182 3.108 8.448 7.512 0.266 4.402-3.108 8.18-7.51 8.446L376.58 767.8c-0.156 0.016-0.312 0.016-0.468 0.016z"
                      fill=""
                    />
                    <path
                      d="M512.016 847.76c-44.406 0-62.362-34.912-63.112-36.396a7.996 7.996 0 0 1 14.286-7.182c0.624 1.218 14.598 27.59 48.826 27.59 34.272 0 48.23-26.464 48.808-27.59a8.016 8.016 0 0 1 10.742-3.514 7.99 7.99 0 0 1 3.544 10.696c-0.748 1.484-18.706 36.396-63.094 36.396zM472.042 655.896a7.988 7.988 0 0 1-7.994-7.994v-63.954a7.99 7.99 0 0 1 7.994-7.996 7.99 7.99 0 0 1 7.996 7.996v63.954a7.99 7.99 0 0 1-7.996 7.994zM551.97 655.896a7.988 7.988 0 0 1-7.994-7.994v-63.954c0-4.42 3.576-7.996 7.994-7.996s7.994 3.576 7.994 7.996v63.954a7.988 7.988 0 0 1-7.994 7.994z"
                      fill=""
                    />
                    <path
                      d="M472.042 591.944a8 8 0 0 1-6.246-2.998 8.006 8.006 0 0 1 1.25-11.242l39.972-31.976c3.482-2.75 8.496-2.188 11.242 1.248a8.004 8.004 0 0 1-1.248 11.242l-39.972 31.976a7.966 7.966 0 0 1-4.998 1.75z"
                      fill=""
                    />
                    <path
                      d="M551.97 591.944a7.916 7.916 0 0 1-4.996-1.75l-39.956-31.976a8.008 8.008 0 0 1-1.25-11.242 8.006 8.006 0 0 1 11.242-1.248l39.956 31.976a8.006 8.006 0 0 1 1.25 11.242 8 8 0 0 1-6.246 2.998zM472.042 559.964a7.988 7.988 0 0 1-7.994-7.994v-111.92a7.99 7.99 0 0 1 7.994-7.994 7.99 7.99 0 0 1 7.996 7.994v111.92a7.99 7.99 0 0 1-7.996 7.994z"
                      fill=""
                    />
                    <path
                      d="M472.042 448.044a7.988 7.988 0 0 1-7.994-7.994c0-13.224-10.758-23.982-23.982-23.982s-23.982 10.758-23.982 23.982a7.988 7.988 0 0 1-7.994 7.994 7.99 7.99 0 0 1-7.996-7.994c0-22.04 17.924-39.97 39.972-39.97s39.972 17.932 39.972 39.97a7.99 7.99 0 0 1-7.996 7.994z"
                      fill=""
                    />
                    <path
                      d="M440.066 480.024c-22.046 0-39.972-17.934-39.972-39.972a7.99 7.99 0 0 1 7.996-7.994 7.99 7.99 0 0 1 7.994 7.994c0 13.226 10.758 23.984 23.982 23.984 4.418 0 7.994 3.576 7.994 7.994s-3.576 7.994-7.994 7.994zM551.97 559.964a7.988 7.988 0 0 1-7.994-7.994v-111.92c0-4.418 3.576-7.994 7.994-7.994s7.994 3.576 7.994 7.994v111.92a7.988 7.988 0 0 1-7.994 7.994z"
                      fill=""
                    />
                    <path
                      d="M615.926 448.044a7.988 7.988 0 0 1-7.994-7.994c0-13.224-10.758-23.982-23.984-23.982-13.224 0-23.982 10.758-23.982 23.982 0 4.42-3.576 7.994-7.994 7.994s-7.994-3.574-7.994-7.994c0-22.04 17.924-39.97 39.97-39.97s39.972 17.932 39.972 39.97a7.988 7.988 0 0 1-7.994 7.994z"
                      fill=""
                    />
                    <path
                      d="M583.948 480.024c-4.418 0-7.994-3.576-7.994-7.996s3.576-7.994 7.994-7.994c13.226 0 23.984-10.758 23.984-23.984a7.988 7.988 0 0 1 7.994-7.994 7.99 7.99 0 0 1 7.994 7.994c0 22.04-17.926 39.974-39.972 39.974zM328.146 440.05a7.99 7.99 0 0 1-7.994-7.994c0-105.792 86.062-191.864 191.864-191.864a7.99 7.99 0 0 1 7.994 7.994 7.99 7.99 0 0 1-7.994 7.996c-96.978 0-175.876 78.896-175.876 175.874a7.988 7.988 0 0 1-7.994 7.994zM520.01 480.024h-15.99c-4.418 0-7.994-3.576-7.994-7.996s3.576-7.994 7.994-7.994h15.99c4.418 0 7.994 3.576 7.994 7.994s-3.576 7.996-7.994 7.996z"
                      fill=""
                    />
                    <path
                      d="M53.888 687.938c-3.264 0-6.324-2-7.51-5.246C26.456 628.168 16.354 570.738 16.354 512 16.354 238.704 238.694 16.354 512 16.354c70.856 0 139.198 14.538 203.12 43.204a7.99 7.99 0 0 1 4.03 10.564c-1.794 4.036-6.542 5.816-10.554 4.02-61.864-27.74-128.02-41.8-196.596-41.8C247.516 32.342 32.342 247.516 32.342 512c0 56.866 9.774 112.452 29.056 165.196a8.004 8.004 0 0 1-4.762 10.258 8.084 8.084 0 0 1-2.748 0.484z"
                      fill=""
                    />
                    <path
                      d="M663.876 80.308a8.012 8.012 0 0 1-7.932-7.09 7.992 7.992 0 0 1 7.042-8.844l47.964-5.464c4.262-0.57 8.338 2.638 8.854 7.034a7.996 7.996 0 0 1-7.042 8.846l-47.964 5.464a7.526 7.526 0 0 1-0.922 0.054z"
                      fill=""
                    />
                    <path
                      d="M711.858 74.844a7.97 7.97 0 0 1-6.386-3.186l-31.976-42.5a7.994 7.994 0 1 1 12.772-9.618l31.976 42.5a7.994 7.994 0 0 1-6.386 12.804z"
                      fill=""
                    />
                    <path
                      d="M512 1007.646c-70.888 0-139.23-14.552-203.136-43.236-4.028-1.794-5.824-6.542-4.012-10.57a8.012 8.012 0 0 1 10.57-4.012c61.832 27.746 127.972 41.83 196.58 41.83 264.482 0 479.658-215.176 479.658-479.658 0-56.85-9.76-112.412-29.012-165.14a7.998 7.998 0 0 1 4.762-10.25 8.006 8.006 0 0 1 10.258 4.77c19.892 54.494 29.98 111.898 29.98 170.622C1007.646 785.304 785.304 1007.646 512 1007.646z"
                      fill=""
                    />
                    <path
                      d="M312.126 965.114a7.996 7.996 0 0 1-7.932-7.088 7.968 7.968 0 0 1 7.042-8.838l47.966-5.434c4.31-0.562 8.338 2.624 8.852 7.028a7.968 7.968 0 0 1-7.042 8.836l-47.966 5.434a6.148 6.148 0 0 1-0.92 0.062z"
                      fill=""
                    />
                    <path
                      d="M344.12 1007.646a7.95 7.95 0 0 1-6.386-3.186l-31.978-42.532c-2.654-3.53-1.952-8.556 1.578-11.196 3.56-2.656 8.556-1.954 11.196 1.576l31.978 42.534c2.654 3.528 1.952 8.554-1.578 11.196a8.06 8.06 0 0 1-4.81 1.608z"
                      fill=""
                    />
                    <path
                      d="M743.834 192.228a7.99 7.99 0 0 1-7.994-7.994V144.262c0-4.418 3.576-7.994 7.994-7.994s7.994 3.576 7.994 7.994v39.972a7.99 7.99 0 0 1-7.994 7.994z"
                      fill=""
                    />
                    <path
                      d="M879.736 288.16c-79.35 0-143.896-64.556-143.896-143.898S800.386 0.366 879.736 0.366s143.898 64.554 143.898 143.896-64.548 143.898-143.898 143.898z m0-271.806c-70.528 0-127.908 57.38-127.908 127.908 0 70.528 57.38 127.91 127.908 127.91s127.91-57.38 127.91-127.91c0-70.528-57.382-127.908-127.91-127.908z"
                      fill=""
                    />
                    <path
                      d="M879.736 328.13c-79.35 0-143.896-64.554-143.896-143.896 0-4.42 3.576-7.994 7.994-7.994s7.994 3.574 7.994 7.994c0 70.528 57.38 127.908 127.908 127.908s127.91-57.38 127.91-127.908a7.988 7.988 0 0 1 7.994-7.994 7.988 7.988 0 0 1 7.994 7.994c0 79.342-64.548 143.896-143.898 143.896z"
                      fill=""
                    />
                    <path
                      d="M879.736 200.222a7.99 7.99 0 0 1-7.994-7.994V96.296a7.99 7.99 0 0 1 7.994-7.994 7.99 7.99 0 0 1 7.996 7.994v95.932a7.99 7.99 0 0 1-7.996 7.994zM1015.64 192.228a7.988 7.988 0 0 1-7.994-7.994V144.262a7.988 7.988 0 0 1 7.994-7.994 7.99 7.99 0 0 1 7.994 7.994v39.972a7.99 7.99 0 0 1-7.994 7.994zM823.778 316.092a7.988 7.988 0 0 1-7.994-7.994v-39.972a7.988 7.988 0 0 1 7.994-7.994 7.99 7.99 0 0 1 7.994 7.994v39.972a7.988 7.988 0 0 1-7.994 7.994zM951.686 307.522a7.99 7.99 0 0 1-7.994-7.996v-39.972c0-4.418 3.576-7.994 7.994-7.994s7.994 3.576 7.994 7.994v39.972a7.99 7.99 0 0 1-7.994 7.996z"
                      fill=""
                    />
                    <path
                      d="M8.36 887.732a7.988 7.988 0 0 1-7.994-7.994v-39.972a7.988 7.988 0 0 1 7.994-7.994 7.99 7.99 0 0 1 7.994 7.994v39.972a7.99 7.99 0 0 1-7.994 7.994z"
                      fill=""
                    />
                    <path
                      d="M144.262 983.664c-79.35 0-143.896-64.548-143.896-143.898s64.546-143.896 143.896-143.896 143.898 64.546 143.898 143.896-64.548 143.898-143.898 143.898z m0-271.806c-70.528 0-127.908 57.38-127.908 127.908s57.38 127.91 127.908 127.91 127.908-57.382 127.908-127.91-57.38-127.908-127.908-127.908z"
                      fill=""
                    />
                    <path
                      d="M144.262 1023.634c-79.35 0-143.896-64.548-143.896-143.896a7.99 7.99 0 0 1 7.994-7.996 7.99 7.99 0 0 1 7.994 7.996c0 70.528 57.38 127.908 127.908 127.908s127.908-57.38 127.908-127.908c0-4.42 3.576-7.996 7.996-7.996s7.994 3.576 7.994 7.996c0 79.348-64.548 143.896-143.898 143.896z"
                      fill=""
                    />
                    <path
                      d="M144.262 895.726a7.99 7.99 0 0 1-7.994-7.994V791.8a7.99 7.99 0 0 1 7.994-7.996 7.99 7.99 0 0 1 7.994 7.996v95.932a7.99 7.99 0 0 1-7.994 7.994zM280.166 887.732a7.992 7.992 0 0 1-7.996-7.994v-39.972c0-4.418 3.576-7.994 7.996-7.994s7.994 3.576 7.994 7.994v39.972a7.99 7.99 0 0 1-7.994 7.994zM88.302 1011.612a7.988 7.988 0 0 1-7.994-7.994v-39.97c0-4.42 3.576-7.994 7.994-7.994s7.994 3.574 7.994 7.994v39.97a7.988 7.988 0 0 1-7.994 7.994zM216.21 1003.024a7.988 7.988 0 0 1-7.994-7.992V955.06a7.99 7.99 0 0 1 7.994-7.994 7.99 7.99 0 0 1 7.994 7.994v39.972a7.988 7.988 0 0 1-7.994 7.992z"
                      fill=""
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="abt-service-title">Our Vision</h3>
                  <p>
                    To be an international company of choice in the delivery of
                    world-class business solutions through innovative
                    applications of state of the art products and services in
                    the oil, gas and energy sector.
                  </p>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={1000}>
              <div className="abt-service-body">
                <div className="abt-service-icon">
                  <svg
                    fill="#000000"
                    height="50px"
                    width="50px"
                    id="Icons"
                    viewBox="0 0 32 32"
                  >
                    <path
                      d="M30.9,5.6C30.8,5.2,30.4,5,30,5h-3V2c0-0.4-0.2-0.8-0.6-0.9C26,0.9,25.6,1,25.3,1.3l-4,4C21.1,5.5,21,5.7,21,6v3.6l-5.7,5.7
	c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3l5.7-5.7H26c0.3,0,0.5-0.1,0.7-0.3l4-4C31,6.4,31.1,6,30.9,5.6z"
                    />
                    <path
                      d="M18.1,18.1C17.6,18.7,16.8,19,16,19s-1.6-0.3-2.1-0.9c-1.2-1.2-1.2-3.1,0-4.2l2.8-2.8C16.5,11,16.2,11,16,11
	c-2.8,0-5,2.2-5,5s2.2,5,5,5s5-2.2,5-5c0-0.2,0-0.5-0.1-0.7L18.1,18.1z"
                    />
                    <path
                      d="M28.1,12.1C27.6,12.7,26.8,13,26,13h-2.8l-0.7,0.7c0.3,0.7,0.4,1.5,0.4,2.3c0,3.9-3.1,7-7,7s-7-3.1-7-7s3.1-7,7-7
	c0.8,0,1.6,0.2,2.3,0.4L19,8.8V6c0-0.8,0.3-1.6,0.9-2.1l1-1C19.3,2.3,17.7,2,16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14
	c0-1.7-0.3-3.3-0.9-4.9L28.1,12.1z"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="abt-service-title">Our Mission</h3>
                  <p>
                    To provide our clients with products and services in a
                    manner that seeks to exceed international best practices
                    with a view to guarantee continuous business growth and
                    expansion.
                  </p>
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeIn" animateOnce={true} delay={2000}>
              <div className="abt-service-body">
                <div className="abt-service-icon">
                  <svg
                    width="50px"
                    height="50px"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M3,0 L5,0 L5,4.12602 C6.7252,4.57006 8,6.13616 8,8 C8,9.86384 6.7252,11.4299 5,11.874 L5,16 L3,16 L3,11.874 C1.27477,11.4299 0,9.86384 0,8 C0,6.13616 1.27477,4.57006 3,4.12602 L3,0 Z M4,10 C5.10457,10 6,9.10457 6,8 C6,6.89543 5.10457,6 4,6 C2.89543,6 2,6.89543 2,8 C2,9.10457 2.89543,10 4,10 Z"
                      transform="translate(3.67)"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="abt-service-title">Our Committment</h3>
                  <p>
                    At Artino Projects Limited, we are committed to conducting
                    our business with the highest level of integrity and earning
                    the trust of our clients. Our code of integrity contains our
                    guilding ethical principles and sets forth the behavior we
                    expect of all our employees, directors and officers, as well
                    as our business partners.
                  </p>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      {/* Core Values */}
      <div>
        <CoreValues></CoreValues>
      </div>
      {/* Feedback */}
      <div style={{backgroundColor:'whitesmoke'}}>
        <Feedback></Feedback>
      </div>
    </div>
  );
};

export default About;
