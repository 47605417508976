// src/components/Partners.js
import React, { useEffect, useRef } from "react";
import "../styles/partners.css";
import Logo1 from "../assets/images/partners/1.png";
import Logo2 from "../assets/images/partners/2.png";
import Logo3 from "../assets/images/partners/3.jpeg";
import Logo4 from "../assets/images/partners/4.png";
import Logo5 from "../assets/images/partners/5.webp";

const logos = [
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
];

const Partners = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      const totalWidth = containerRef.current.scrollWidth;
      const viewportWidth = containerRef.current.clientWidth;
      containerRef.current.style.setProperty(
        "--total-width",
        `${totalWidth}px`
      );
      containerRef.current.style.setProperty(
        "--viewport-width",
        `${viewportWidth}px`
      );
    }
  }, []);

  return (
    <div className="partner-main">
      <div className="pt-logo-slider">
        <div className="pt-logo-container" ref={containerRef}>
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt={`Logo ${index + 1}`}
              className="pt-logo"
            />
          ))}
          {logos.map((logo, index) => (
            <img
              key={`duplicate-${index}`}
              src={logo}
              alt={`Logo duplicate ${index + 1}`}
              className="logo"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
