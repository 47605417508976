import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/home.css";

const ProjectComp = ({ allowAnimation = true }) => {
  if (allowAnimation) {
    return (
      <div className="pm-abt-contain" style={{ backgroundColor: "white" }}>
        <div>
          <br></br>
          <h2 style={{ textAlign: "center" }}>
            Our <span className="fg-shade-one-dark">Projects</span>
          </h2>
        </div>
        <div className="pm-service-container">
          <ScrollAnimation
            animateIn="bounceInLeft"
            animateOnce={true}
            delay={0}
          >
            <div className="pm-service-body pm-inner-bg-1">
              <div className="pm-service-inner">
                <h3 className="pm-service-title">
                  LNG Site Ikot Abasi Project
                </h3>
                <p>
                  We're partnering with CHCI International on an ongoing project
                  at the LNG site in Ikot Abasi, delivering expert pile
                  foundation and diaphragm walling solutions. Our team is
                  utilizing cutting-edge techniques to ensure successful
                  execution and provide a solid base for future operations.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="bounceInLeft"
            animateOnce={true}
            delay={500}
          >
            <div className="pm-service-body pm-inner-bg-4">
              <div className="pm-service-inner">
                <h3 className="pm-service-title">
                  Darycet Concrete Project, Egbema
                </h3>
                <p>
                  We're executing a concrete/casting project at Darycet in
                  Egbema, Imo State, delivering high-quality concrete solutions
                  with precision and expertise.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="bounceInLeft"
            animateOnce={true}
            delay={1000}
          >
            <div className="pm-service-body pm-inner-bg-3">
              <div className="pm-service-inner">
                <h3 className="pm-service-title">
                  Walter-Smith Modular Refinery Project, Ohaji-Egbema
                </h3>
                <p>
                  We're driving progress on the piling and earthwork project for
                  Walter-Smith's modular refinery in Ohaji-Egbema, delivering
                  foundational expertise for a successful operation.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn="bounceInLeft"
            animateOnce={true}
            delay={1000}
          >
            <div className="pm-service-body pm-inner-bg-2">
              <div className="pm-service-inner">
                <h3 className="pm-service-title">
                  Dangote Terminal Onne Project
                </h3>
                <p>
                  We're supplying high-quality bentonite to Sinoma at the
                  Dangote terminal in Onne, supporting the success of their
                  operations with our reliable materials and services.
                </p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    );
  } else {
    return (
      <div className="pm-abt-contain" style={{ backgroundColor: "white" }}>
        <div>
          <br></br>
          <h2 style={{ textAlign: "center" }}>
            Our <span className="fg-shade-one-dark">Projects</span>
          </h2>
        </div>
        <div className="pm-service-container">
            <div className="pm-service-body pm-inner-bg-1">
              <div className="pm-service-inner">
                <h3 className="pm-service-title">
                  LNG Site Ikot Abasi Project
                </h3>
                <p>
                  We're partnering with CHCI International on an ongoing project
                  at the LNG site in Ikot Abasi, delivering expert pile
                  foundation and diaphragm walling solutions. Our team is
                  utilizing cutting-edge techniques to ensure successful
                  execution and provide a solid base for future operations.
                </p>
              </div>
            </div>
            <div className="pm-service-body pm-inner-bg-4">
              <div className="pm-service-inner">
                <h3 className="pm-service-title">
                  Darycet Concrete Project, Egbema
                </h3>
                <p>
                  We're executing a concrete/casting project at Darycet in
                  Egbema, Imo State, delivering high-quality concrete solutions
                  with precision and expertise.
                </p>
              </div>
            </div>
            <div className="pm-service-body pm-inner-bg-3">
              <div className="pm-service-inner">
                <h3 className="pm-service-title">
                  Walter-Smith Modular Refinery Project, Ohaji-Egbema
                </h3>
                <p>
                  We're driving progress on the piling and earthwork project for
                  Walter-Smith's modular refinery in Ohaji-Egbema, delivering
                  foundational expertise for a successful operation.
                </p>
              </div>
            </div>
            <div className="pm-service-body pm-inner-bg-2">
              <div className="pm-service-inner">
                <h3 className="pm-service-title">
                  Dangote Terminal Onne Project
                </h3>
                <p>
                  We're supplying high-quality bentonite to Sinoma at the
                  Dangote terminal in Onne, supporting the success of their
                  operations with our reliable materials and services.
                </p>
              </div>
            </div>
        </div>
      </div>
    );
  }
};

export default ProjectComp;
