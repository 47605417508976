import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import "animate.css/animate.compat.css"
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Services from "./pages/services";
import Projects from "./pages/projects";
import Header from "./components/header";
import Footer from "./components/footer";
import Messenger from "./components/messenger";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home></Home>} />
        <Route path="/about" element={<About></About>} />
        <Route path="/services" element={<Services></Services>} />
        <Route path="/projects" element={<Projects></Projects>} />
      </Routes>
      <Messenger></Messenger>
      <Footer></Footer>
    </BrowserRouter>
  </React.StrictMode>
);
