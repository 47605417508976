import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/home.css";
import Partners from "../components/partners";
import Feedback from "../components/feedback";
import ServicesComp from "../service/servicesComp";
import ProjectComp from "../projects/projectComp";
import Quote from "../components/quote";

const Home = () => {
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };
  return (
    <div>
      {/* Slides */}
      <div className="hm-slide">
        <div className="slider-container">
          <Slider {...settings}>
            <div className="hm-slide-con">
              <div className="hm-slide-img hm-slide-img-1"></div>
              <div className="hm-slide-fade"></div>
              <div className="hm-slide-content">
                <p className="hm-slide-content-title">
                  Your Global Energy Partner
                </p>
                <p className="hm-slide-content-sub">
                  {" "}
                  We are your International Partner for World-Class Innovative
                  Energy Solutions. Artino delivers Superior Products/Equipments
                  with Global Best Practices
                </p>
                <div className="hm-slide-content-but">Learn more</div>
              </div>
            </div>
            <div className="hm-slide-con">
              <div className="hm-slide-img hm-slide-img-2"></div>
              <div className="hm-slide-fade"></div>
              <div className="hm-slide-content">
                <p className="hm-slide-content-title">
                  Comprehensive Drilling Solutions
                </p>
                <p className="hm-slide-content-sub">
                  {" "}
                  Equip your drilling projects with industry-leading tools and
                  materials. From start to finish, we provide everything you
                  need for efficient and reliable operations.
                </p>
                <div className="hm-slide-content-but">Our Projects</div>
              </div>
            </div>
            <div className="hm-slide-con">
              <div className="hm-slide-img hm-slide-img-3"></div>
              <div className="hm-slide-fade"></div>
              <div className="hm-slide-content">
                <p className="hm-slide-content-title">
                  Leading Distributor of Essential Industrial Materials
                </p>
                <p className="hm-slide-content-sub">
                  Supplying high-quality Cement, Caustic Soda, and Bentonite
                  Powder for your industrial needs. Trust us for reliable
                  delivery and exceptional service.
                </p>
                <div className="hm-slide-content-but">Get Quote</div>
              </div>
            </div>
            <div className="hm-slide-con">
              <div className="hm-slide-img hm-slide-img-4"></div>
              <div className="hm-slide-fade"></div>
              <div className="hm-slide-content">
                <p className="hm-slide-content-title">
                  Excellence in Every Step
                </p>
                <p className="hm-slide-content-sub">
                  {" "}
                  Building durable and efficient roads with precision, we
                  deliver infrastructure solutions that drive progress.
                  Committed to continuous improvement, we provide exceptional
                  quality and industry-leading expertise for your success.
                </p>
                <div className="hm-slide-content-but">About us</div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      {/* About Us */}
      <ScrollAnimation animateIn="bounceInRight" animateOnce="true">
        <div className="hm-abt-contain">
          <div>
            <h2 style={{ textAlign: "center" }}>
              About <span className="fg-shade-one-dark">Artino</span>
            </h2>
            <img
              src={require("../assets/images/about.jpg")}
              alt="artino about us"
              className="hm-abt-img"
            />
          </div>
          <div className="hm-abt-content">
            <p>
              Artino Projects Limited is a leader in delivering world-class
              solutions in the oil, gas, and energy sectors. We specialize in
              the distribution of essential industrial materials, including
              Cement, Caustic Soda, and Bentonite Powder, and offer top-quality
              drilling equipment. Additionally, we are renowned contractors in
              road construction, delivering durable and efficient
              infrastructure.
            </p>
            <p>
              Guided by core values of Integrity, Innovation, and Excellence, we
              are committed to exceeding global standards and fostering
              continuous improvement. Our mission is to provide exceptional
              products and services that ensure the success and growth of our
              clients. At Artino Projects Limited, we build trust through
              ethical practices and a dedication to quality.
            </p>
          </div>
        </div>
      </ScrollAnimation>
      {/*  Partners */}
      <ScrollAnimation animateIn="bounceInLeft" animateOnce="true">
        <div className="partners hm-abt-contain">
          <h2 style={{ textAlign: "center" }}>
            Our <span className="fg-shade-one-dark">Partners</span>
          </h2>
          <p className="additional-info" style={{ textAlign: "center" }}>
            Our strategic partnerships enable us to deliver unparalleled
            solutions, expert support, and exceptional value in the oil and gas
            industry, driving operational excellence and powering your success.
          </p>
          <div className="services-offered">
            <Partners />
          </div>
        </div>
      </ScrollAnimation>
      {/* Services */}
      <ServicesComp></ServicesComp>
      {/* Expertise */}
      <div class="home-projects">
        <div class="project-title">
          Unparalleled Expertise & Abundant experience in the industry makes us
          special.
        </div>
        <div class="project-scope">
          <ScrollAnimation
            animateIn="bounce"
            initiallyVisible={true}
            animateOnce={true}
            delay={1000}
          >
            <div>
              <p class="project-scope-title">10</p>
              <p class="project-scope-subcontent">Running Projects</p>
            </div>
          </ScrollAnimation>
          <div>
            <ScrollAnimation
              animateIn="bounce"
              initiallyVisible={true}
              animateOnce={true}
              delay={2000}
            >
              <p class="project-scope-title">11</p>
              <p class="project-scope-subcontent">Clients</p>
            </ScrollAnimation>
          </div>
          <div>
            <ScrollAnimation
              animateIn="bounce"
              initiallyVisible={true}
              animateOnce={true}
              delay={3000}
            >
              <p class="project-scope-title">20+</p>
              <p class="project-scope-subcontent">Specialist</p>
            </ScrollAnimation>
          </div>
          <div>
            <ScrollAnimation
              animateIn="bounce"
              initiallyVisible={true}
              animateOnce={true}
              delay={4000}
            >
              <p class="project-scope-title">50</p>
              <p class="project-scope-subcontent">Man Team</p>
            </ScrollAnimation>
          </div>
        </div>
      </div>
      {/* Projects */}
      <ProjectComp></ProjectComp>
      {/* Quote */}
      <div style={{ backgroundColor: "whitesmoke" }} id="getquote">
        <Quote></Quote>
      </div>
      {/* Feedback */}
      <div style={{ backgroundColor: "white" }}>
        <Feedback></Feedback>
      </div>
    </div>
  );
};

export default Home;
