import React, { useState } from "react";
import "../styles/contact.css";
import { GetQuote } from "../server/server";

const Quote = () => {
  const [fullname, fullnameChanged] = useState("");
  const [organisation, organisationChanged] = useState("");
  const [email, emailChanged] = useState("");
  const [phone, phoneChanged] = useState("");
  const [service, serviceChanged] = useState("");
  const [note, noteChanged] = useState("");
  const sendQuote = async () => {
    await GetQuote({
      fullname: fullname,
      email: email,
      phone: phone,
      service: service,
      note: note,
    });
    fullnameChanged("");
    emailChanged("");
    phoneChanged("");
    serviceChanged("");
    noteChanged("");
    alert(
      "We have noted your quote request, an Artino Project personnel will contact you within the hour"
    );
  };
  return (
    <div className="contact" id="contact">
      <div className="contact-section">
        <div className="get-in-touch">
          <h2>Let us handle your Project </h2>
          <p>We typically reply within 10minutes!</p>
          <div className="contact-grid">
            <div>
              <input
                type="text"
                name="name"
                placeholder="Your Name (required)"
                value={fullname}
                onChange={(e) => fullnameChanged(e.target.value)}
                required
              />
              <input
                type="text"
                name="name"
                placeholder="Organisation (required)"
                value={organisation}
                onChange={(e) => organisationChanged(e.target.value)}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Your Email (required)"
                value={email}
                onChange={(e) => emailChanged(e.target.value)}
                required
              />
              <input
                type="tel"
                name="subject"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => phoneChanged(e.target.value)}
              />
            </div>
            <div>
              <select
                name="status"
                required
                value={service}
                onChange={(e) => serviceChanged(e.target.value)}
              >
                <option value="" id="service" disabled selected hidden>
                  --Select Project Type--
                </option>
                <option value="flight">Oil and Gas Servicing</option>
                <option value="flight">Bulk Cement Supply</option>
                <option value="tour">Bertonite Powder Supply</option>
                <option value="visa">Caustic Soda Flakes</option>
                <option value="hotel">Mud Pump and Parts</option>
                <option value="airport">General Services</option>
              </select>
              <input
                type="text"
                className="contact-budget"
                name="subject"
                placeholder="Budget Range (1M NGN - 5M NGN)"
                value={phone}
                onChange={(e) => phoneChanged(e.target.value)}
              />
              <textarea
                style={{ width: "100%" }}
                name="message"
                placeholder="Type your Message"
                value={note}
                onChange={(e) => noteChanged(e.target.value)}
              />
            </div>
          </div>
          <br></br>
          <button onClick={() => sendQuote()}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Quote;
